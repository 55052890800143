import { useMemo } from "react";
import { FormPatientProcedureVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";

export const useGroupedProcedures = (procedures: FormPatientProcedureVO[]) => {
  return useMemo(() => {
    const grouped = procedures.reduce(
      (accum, procedure) => {
        const priority = procedure.priority ?? Number.POSITIVE_INFINITY;

        if (isDefined(accum[priority])) {
          accum[priority].push(procedure);
        } else {
          accum[priority] = [procedure];
        }

        return accum;
      },
      {} as Record<string, [FormPatientProcedureVO, ...FormPatientProcedureVO[]]>
    );

    return Object.values(grouped).sort(([a], [b]) => {
      return (a.priority ?? Number.POSITIVE_INFINITY) - (b.priority ?? Number.POSITIVE_INFINITY);
    });
  }, [procedures]);
};
