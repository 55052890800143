import React from "react";
import { useTranslation } from "react-i18next";
import { FormPatientProcedureVO, FormProcedureTableElementVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { isDefined } from "@libs/utils/types";
import { normal12, semibold12 } from "assets/styles/textSize";
import { getProcedureDescriptions } from "components/PatientForms/utils";
import { usePatientProcedureTotals } from "components/PatientForms/hooks/usePatientProcedureTotals";
import { useGroupedProcedures } from "components/PatientForms/FormElements/PatientFormProcedureTableElement/useGroupedProcedures";
import { EMPTY_CELL } from "components/UI/GridTableComponents";

const TableTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={semibold12}>{children}</div>
);
const TableCell: React.FC<{ children: React.ReactNode; isLast?: boolean }> = ({ children, isLast }) => (
  <div className={cx(!isLast && "pb-1", normal12)}>{children}</div>
);

export const FeeTitles: React.FC<{ includeUcrFee: boolean; showSecondary: boolean }> = ({
  includeUcrFee,
  showSecondary,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {includeUcrFee && <TableTitle>{t("UCR Fee")}</TableTitle>}
      <TableTitle>{t("Neg Rate")}</TableTitle>
      {showSecondary ? (
        <>
          <TableTitle>{t("Prim Ins Est")}</TableTitle>
          <TableTitle>{t("Sec Ins Est")}</TableTitle>
        </>
      ) : (
        <TableTitle>{t("Ins Est")}</TableTitle>
      )}
      <TableTitle>{t("Pt Est")}</TableTitle>
    </>
  );
};

const Grid: React.FC<{
  includeUcrFee: boolean;
  showSecondary: boolean;
  className?: string;
  children: React.ReactNode;
}> = ({ includeUcrFee, showSecondary, className, children }) => {
  const extraColumns = Number(showSecondary) + Number(includeUcrFee);

  return (
    <div
      className={cx(
        "grid border border-greyLightest p-3 gap-1 rounded-md",

        extraColumns === 2 ? "grid-cols-5" : extraColumns === 1 ? "grid-cols-4" : "grid-cols-3",
        className
      )}
    >
      {children}
    </div>
  );
};

const ProcedureItem: React.FC<{
  procedure: FormPatientProcedureVO;
  includeUcrFee: boolean;
  showSecondary: boolean;
}> = ({ procedure, includeUcrFee, showSecondary }) => {
  const {
    priority,
    procedureArea,
    ucrRate,
    negotiatedRate,
    insuranceAmount,
    primaryInsuranceAmount,
    secondaryInsuranceAmount,
    patientAmount,
    cdtCode,
  } = procedure;
  const spacer = includeUcrFee ? <div /> : null;
  const { t } = useTranslation();

  return (
    <Grid includeUcrFee={includeUcrFee} showSecondary={showSecondary}>
      <TableTitle>{t("Priority")}</TableTitle>
      <TableTitle>{t("Tth/Surf")}</TableTitle>
      <TableTitle>{t("Code")}</TableTitle>
      {spacer}
      <TableCell>{priority ?? EMPTY_CELL}</TableCell>
      <TableCell>{procedureArea ?? EMPTY_CELL}</TableCell>
      <TableCell>#{cdtCode}</TableCell>
      {spacer}

      <div className="col-span-full flex flex-col gap-1">
        <TableTitle>{t("Description")}</TableTitle>
        <TableCell>
          {getProcedureDescriptions(procedure, t).map((description, i) => (
            <div key={i}>{description}</div>
          ))}
        </TableCell>
      </div>
      <FeeTitles includeUcrFee={includeUcrFee} showSecondary={showSecondary} />
      {includeUcrFee && <TableCell isLast>{formatCurrency(ucrRate)}</TableCell>}
      <TableCell isLast>{formatCurrency(negotiatedRate)}</TableCell>
      {showSecondary ? (
        <>
          <TableCell isLast>{formatCurrency(primaryInsuranceAmount ?? 0)}</TableCell>
          <TableCell isLast>{formatCurrency(secondaryInsuranceAmount ?? 0)}</TableCell>
        </>
      ) : (
        <TableCell isLast>{formatCurrency(insuranceAmount)}</TableCell>
      )}
      <TableCell isLast>{formatCurrency(patientAmount)}</TableCell>
    </Grid>
  );
};

const ProcedureTotals: React.FC<{
  includeUcrFee: boolean;
  showSecondary: boolean;
  ucrRate: number;
  negotiatedRate: number;
  primaryInsuranceAmount: number;
  secondaryInsuranceAmount: number;
  insuranceAmount: number;
  patientAmount: number;
  title: string;
}> = ({
  includeUcrFee,
  showSecondary,
  ucrRate,
  negotiatedRate,
  primaryInsuranceAmount,
  secondaryInsuranceAmount,
  insuranceAmount,
  patientAmount,
  title,
}) => {
  return (
    <Grid includeUcrFee={includeUcrFee} showSecondary={showSecondary} className="bg-slate-50">
      <div className="font-sansSemiBold text-sm col-span-full">{title}</div>

      <FeeTitles includeUcrFee={includeUcrFee} showSecondary={showSecondary} />
      {includeUcrFee && <TableCell isLast>{formatCurrency(ucrRate)}</TableCell>}
      <TableCell isLast>{formatCurrency(negotiatedRate)}</TableCell>
      {showSecondary ? (
        <>
          <TableCell isLast>{formatCurrency(primaryInsuranceAmount)}</TableCell>
          <TableCell isLast>{formatCurrency(secondaryInsuranceAmount)}</TableCell>
        </>
      ) : (
        <TableCell isLast>{formatCurrency(insuranceAmount)}</TableCell>
      )}
      <TableCell isLast>{formatCurrency(patientAmount)}</TableCell>
    </Grid>
  );
};

const ProceduresGroup: React.FC<{
  procedures: [FormPatientProcedureVO, ...FormPatientProcedureVO[]];
  includeUcrFee: boolean;
  hasSecondary: boolean;
  includeSubtotal: boolean;
}> = ({ procedures, includeUcrFee, hasSecondary, includeSubtotal }) => {
  const { t } = useTranslation();
  const totals = usePatientProcedureTotals(procedures);
  const [procedure] = procedures;
  const { priority } = procedure;

  return (
    <div className="flex flex-col gap-1">
      {procedures.map((currentProcedure, j) => {
        return (
          <ProcedureItem
            key={`${currentProcedure.id}-${j}`}
            procedure={currentProcedure}
            includeUcrFee={includeUcrFee}
            showSecondary={hasSecondary}
          />
        );
      })}
      {includeSubtotal && (
        <ProcedureTotals
          title={
            isDefined(priority)
              ? t("patientForms.treatmentPlan.subtotalPriority", { priority })
              : t("patientForms.treatmentPlan.subtotalNoPriority")
          }
          includeUcrFee={includeUcrFee}
          showSecondary={hasSecondary}
          {...totals}
        />
      )}
    </div>
  );
};

type Props = {
  element: FormProcedureTableElementVO;
};

// Read only element
export const MobileProcedureTableElement: React.FC<Props> = ({ element }) => {
  const { procedures, settings } = element;
  const { t } = useTranslation();
  const includeUcrFee = settings.includes("DISPLAY_UCR_RATE");
  const hasSecondary = procedures.some((p) => p.secondaryInsuranceAmount);
  const groupedProcedures = useGroupedProcedures(procedures);
  const totals = usePatientProcedureTotals(procedures);

  return (
    <div className="flex flex-col gap-6">
      {groupedProcedures.map((procedureGroup, i) => {
        return (
          <ProceduresGroup
            key={i}
            procedures={procedureGroup}
            includeUcrFee={includeUcrFee}
            hasSecondary={hasSecondary}
            includeSubtotal={groupedProcedures.length > 1}
          />
        );
      })}
      <ProcedureTotals
        title={t("patientForms.treatmentPlan.total")}
        includeUcrFee={includeUcrFee}
        showSecondary={hasSecondary}
        {...totals}
      />
    </div>
  );
};
